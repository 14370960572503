import React, { useContext, createContext, useState } from 'react'

type ThemeType = { theme: string; toggleTheme: () => void }

const ThemeContext: React.Context<ThemeType> = createContext({
    theme: 'light',
    toggleTheme: () => {},
})

export const ThemeProvider = ({
    children,
}: {
    children: React.JSX.Element
}) => {
    const [theme, setTheme] = useState('light')
    const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light')
    const value = { theme, toggleTheme }
    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    )
}

export const useTheme = () => useContext(ThemeContext)
