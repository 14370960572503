import React from 'react'
import PackmanApp from './projects/packman/PackmanApp'
import Home from './home/Home'
import { Routes, Route, Link, BrowserRouter } from 'react-router-dom'
import './App.css'
import Blog from './blog/Blog'
import A_01_06_2024 from './blog/articles/test_01_06_2024/A_01_06_2024'
import Calc from './projects/calculator/Calc'
import Projects from './projects/Projects'
import Registration from './feedback/Registration'
import { ThemeProvider, useTheme } from './common/ThemeContext'
import Switch from './common/Switch'
import { MyTetris } from './examples/tetris/MyTetris'
import RandomUser from './examples/user/RandomUser'
import NextDay from './examples/nextDay/NextDay'

const App = () => {
    const { theme } = useTheme()
    return (
        <BrowserRouter>
            <div
                className="app"
                style={{
                    color: theme === 'light' ? 'black' : 'white',
                    backgroundColor: theme === 'light' ? 'white' : 'black',
                }}
            >
                <nav>
                    <Link to="/" className="nav-item">
                        Home
                    </Link>
                    <Link to="/blog" className="nav-item">
                        Blog
                    </Link>
                    <Link to="/toys" className="nav-item">
                        Toys
                    </Link>
                </nav>
                <Switch></Switch>
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/blog" element={<Blog />}></Route>
                    <Route
                        path="/blog/article-01.06.2024"
                        element={<A_01_06_2024 />}
                    ></Route>
                    <Route path="/toys" element={<Projects />}></Route>
                    <Route
                        path="/toys/knapman"
                        element={<PackmanApp />}
                    ></Route>
                    <Route path="/toys/calc" element={<Calc />}></Route>
                    <Route path="/register" element={<Registration />}></Route>
                    <Route path="/tetris" element={<MyTetris />}></Route>
                    <Route path="/randomUser" element={<RandomUser />}></Route>
                    <Route path="/nextDay" element={<NextDay />}></Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

const Root = () => {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    )
}

export default Root
